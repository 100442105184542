<template>
	<div class="root-tile-container" role="listitem" tabindex="-1">
		<a :href="'/gl-us/eyewear/' + data.partNumber" class="item_container_pdplink search_item_container_pdplink" @click="analyticsTiles(data.style.replace(' ', '-').toLowerCase(), index + 1)">
			<div :id="'suggestionItem_' + (index + 1)" class="modal-search-result-grid">
				<div class="modal-search-result-grid-header">
					<div class="modal-search-result-grid-header-row1">
						<p class="modal-search-result-grid-header-colors">{{ data.colors }} colors</p>
						<template v-if="!!data.offerPrice && !!data.discount">
							<p class="modal-search-result-grid-header-discount" :class="{ thirty: data.discount < 50 }">-{{ data.discount }}%</p>
						</template>
						<template v-else>
							<div class="modal-search-result-grid-badge">
								<template v-if="data.firstBadge">
									<span class="modal-search-result-grid-badge first">
										<p>{{ data.firstBadge }}</p>
									</span>
								</template>
								<template v-else-if="data.secondBadge">
									<span class="modal-search-result-grid-badge second">
										<p>{{ data.secondBadge }}</p>
									</span>
								</template>
							</div>
						</template>
					</div>
					<div class="modal-search-result-grid-header-row2">
						<template v-if="!!data.offerPrice && !!data.discount">
							<div class="modal-search-result-grid-badge">
								<template v-if="data.firstBadge">
									<span class="modal-search-result-grid-badge first">
										<p>{{ data.firstBadge }}</p>
									</span>
								</template>
								<template v-if="data.secondBadge">
									<span class="modal-search-result-grid-badge second">
										<p>{{ data.secondBadge }}</p>
									</span>
								</template>
							</div>
						</template>
						<template v-else-if="!!data.firstBadge && !!data.secondBadge">
							<div class="modal-search-result-grid-badge">
								<template v-if="data.secondBadge">
									<span class="modal-search-result-grid-badge second">
										<p>{{ data.secondBadge }}</p>
									</span>
								</template>
							</div>
						</template>
					</div>
				</div>
				<div class="modal-search-result-img-container">
					<img :src="data.img" :data-original="data.img" :alt="data.brand + data.style + 1" class="modal-search-result-img" loading="lazy" crossorigin="anonymous"/>
					<img :src="data.img2" :data-lazy-loaded="data.img2" :alt="data.brand + data.style + 2" class="modal-search-result-img" crossorigin="anonymous" />
				</div>
				<div class="modal-search-result-description-container">
					<span class="style-name">{{ data.style }}</span
					><br />
					<span class="brand-name">{{ data.brand }}</span
					><br />
					<span class="price" aria-label="Current price: {{ data.price }}">
						<template v-if="data.offerPrice != data.price">
							<del>${{ data.price }}</del
							>&nbsp;
							<strong>${{ data.offerPrice }}</strong>
						</template>
						<template v-else>
							<strong>${{ data.price }}</strong>
						</template>
					</span>
				</div>
			</div>
		</a>
	</div>
</template>

<script>
import tile from "./tile.js";
export default {
	...tile,
};
</script>
<style lang="scss">
@import "./tile.scss";
</style>
