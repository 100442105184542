import { trackAnalytics } from "@/utils";

export default {
	name: "tile",
	props: {
		data: {
			type: Object,
			required: true,
		},
		index: {
			type: Number,
			required: true,
		},
		section: {
			type: String,
			required: true,
		},
	},
	methods: {
		returnElementId(section) {
			switch (section) {
				case "result":
					return "SearchPanel_Results_tile";
				case "bestseller":
				case "noresult":
				default:
					return "SearchPanel_Content_tile";
			}
		},
		analyticsTiles(productNumber, index) {
			trackAnalytics("Click", "hiddenaction", this.returnElementId(this.section) + index, productNumber);
		},
	},
};
