import { getEspotByName } from "@/utils.js";

export default {
	name: 'espotContainer',
	components: {
	},
	props: {
		espotName: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			marketingHtml: this.$store.state.marketingContent[this.espotName] || '',
		};
	},
	computed: {
		marketingHtml() {
			return this.$store.state.marketingContent[this.espotName] || '';
		},
	},
	mounted() {
		if (!this.$store.state.marketingContent[this.espotName]) {
			this.fetchMarketingContent();
		}
	},
	methods: {
		async fetchMarketingContent() {
			await getEspotByName(this.$store.state.data, this.espotName).then((response) => {
				const data = response.data;
				const marketingContent = data.MarketingSpotData[0].baseMarketingSpotActivityData[0].marketingContentDescription[0].marketingText;
				this.marketingHtml = marketingContent;
				this.$store.commit('setMarketingContent', { espotName: this.espotName, content: marketingContent });
			}).catch((error) => {
				console.error('Errore nel recupero dei dati di marketing:', error);
			});
		},
	},
};
